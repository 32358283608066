import Client from './Client';

const endpoint= "api/v1/";

//Listar Persona
export const getEstado = (tipo) => {
    return Client.get(`${endpoint}Estado/Lista?Tipo=${tipo}`);
}
export const getDetalleServicio = (pedidosId) => {
    return Client.get(`${endpoint}Pedidos/DetalleServicio?PedidosId=${pedidosId}`);
}
export const getListaServicio = (data) => {
    return Client.post(`${endpoint}Pedidos/Listar`,data)
}
export const getTipoComprobante = () => {
    return Client.get(`${endpoint}TipoComprobante/Lista`)
}
export const getTipoSeguro = () => {
    return Client.get(`${endpoint}TipoSeguro/Lista`)
}
export const getTipoPago = () => {
    return Client.get(`${endpoint}TipoPago/Lista`)
}
export const getTipoDocumento = () => {
    return Client.get(`${endpoint}TipoDocumento/Listar`)
}
export const getDescagaPpf = (pedidoId) => {
    return Client.get(`${endpoint}ReportesPDF/servivioPDF?PedidosId=${pedidoId}`)
}
export const getListarActividadEstado = (pedidoId) => {
    return Client.get(`${endpoint}Estado/ListaActividadEstado?PedidoId=${pedidoId}`)
}
export const getListarAniversario = (data) => {
    return Client.post(`${endpoint}Pedidos/ProximoAniversio`,data);
}
export const getReportePedidos = (data) => {
    return Client.post(`${endpoint}Reportes/Listar`,data);
}
export const getReporteCompras = (data) => {
    return Client.post(`${endpoint}Reportes/ListarCompras`,data);
}
export const getExportarExcel = (data) => {
    return Client.post(`${endpoint}Reportes/exportar`,data, 'blob');
}
export const getExportarExcelCompras = (data) => {
    return Client.post(`${endpoint}Reportes/exportarCompras`,data, 'blob');
}
export const getMetodPago = () => {
    return Client.get(`${endpoint}TipoPago/MetodoPago`);
}


//Crear
export const crearVenta = (data) => {
    return Client.post(`${endpoint}Pedidos/Venta/Crear`, data)
}
export const CrearRenta = (data) => {
    return Client.post(`${endpoint}Pedidos/Renta/Crear`, data)
}
export const CrearActividadEstadoPedido = (data) => {
    return Client.post(`${endpoint}Estado/CrearActividadEstado`, data)
}
export const crearRegistroPagos = (data) => {
    return Client.post(`${endpoint}Pedidos/RegistroPagos`, data)
}
export const subirDocumento = (data) => {
    return Client.post(`${endpoint}Pedidos/ActualizarDocumentos`, data)
}
//Actualizar 
export const ActualizarNota = (data) => {
    return Client.put(`${endpoint}Pedidos/ActualizarNota`, data)
}