import React from 'react';
import {Form} from 'react-bootstrap';
import ErrorMessage from '../ErrorMessage';
import { useFormikContext } from 'formik';
import { LabelFields } from './LabelFields';
import bsCustomFileInput from 'bs-custom-file-input';
import useConverBase64 from '../../hooks/useConverBase64';
 

function InputFileFields({label, isRequired = false, name, onSelectImage }) {
    const { errors, setFieldValue } = useFormikContext();
    const { converBase64 } = useConverBase64();
    bsCustomFileInput.init(); 

    const onChangeFile = async (file) =>{
        if(file){
            const imageUrl = URL.createObjectURL(file);
            onSelectImage(imageUrl)
        }
        const response = await converBase64(file);
        setFieldValue(name,response)
    }
    return (
        <div>
            {
                label && <LabelFields label={label} isRequired = {isRequired} />
            }
                <Form.File 
                    id="custom-file"
                    label="Cargar archivo"
                    onChange = {(e) => onChangeFile(e.target.files[0])}
                    data-browse="Subir"
                    lang="en"
                    custom
                />
            <ErrorMessage error={JSON.stringify(errors[name])} visible={true}  />
        </div>
    );
}

export default InputFileFields;