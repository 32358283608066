import React, { useEffect, useState } from 'react';
import {Form} from 'react-bootstrap';
import { ItemName, Loading, ModalComponents, Button} from '../../components/Index';
import PaymentActivity from './components/PaymentActivity';
import { getDetalleServicio, getDescagaPpf, CrearActividadEstadoPedido,crearRegistroPagos, subirDocumento } from '../../services/SalesServices';
import { useParams } from "react-router-dom";
import { showErrorAlert,showConfirAlert, showSuccessAlert } from '../../utils/helper/AlertService';
import { createTickedVenta, tickedPagoCuota } from './components/print_ticked';
import PaymentRates from '../sales/components/PaymentRates';
import UserPermissionsComponent from '../auth/UserPermissionsComponent';
import bsCustomFileInput from 'bs-custom-file-input';
import useConverBase64 from '../../hooks/useConverBase64';

function DetailSales() {
    let { pedidoId } = useParams();
    const [detalle, setDetalle] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [actividadPago, setActividadPago] = useState([]);
    const [mdRegitroPago, setMdRegistroPago] = useState(false);
    const [mdDocumento, setMdDocumento] = useState(false);
    const [detalleDocumento, setDetalleDocumento] = useState({});
    const { converBase64 } = useConverBase64();
    const [objDoc, setObjDoc] = useState({
        documentoId: "",
        pedidoId: "",
        data: {}    
    });
    bsCustomFileInput.init(); 
   
    const onSaveActivityPayment = async (value) => {
        value.pedidosId = pedidoId;
        showConfirAlert({
            title: '¿Estás seguro de Registrar el Pago?',
            text: "¡No padrás revertir esto!",
            preConfirm: () => {
                crearRegistroPagos(value).then(() => {
                    fetchData(pedidoId);
                    showSuccessAlert("Pago registrado");
                    setMdRegistroPago(false);
                }).catch((error) =>{
                    showErrorAlert("Error, vuelva intentarlo")
                })
            }
        });
        
    }

    const onClickDescargarPdf = async (id) => {
        try {
            setIsLoading(true)
            const data = await getDescagaPpf(id)
            window.open(data.url,"_blank")
            setIsLoading(false)
        }
        catch(e){
            setIsLoading(false)
            showErrorAlert("Error, no se encontró el contrato en pdf")
        }
    }

    const compartirPorWhatsApp = async (id,numero) => {
        try {
            setIsLoading(true)
            const data = await getDescagaPpf(id)
            const mensajeCodificado = encodeURIComponent('*Funeraria Villa Fe*\nGracias por su compra.\n - Para obtener información importante de su compra, ingresar al siguiente enlace: '+ [data.url]);
            const numeroConCodigoPais = `+51${numero}`;
            const enlaceWhatsApp = `https://wa.me/${numeroConCodigoPais}?text=${mensajeCodificado}`;
            window.open(enlaceWhatsApp, '_blank');
            setIsLoading(false)
        }
        catch(e){
            setIsLoading(false)
            showErrorAlert("Hubo un error al intentar compartir documento");
        }
    };

    const onClickImprimir = async (data) => {
        setIsLoading(true);
        let model = {
            pedidoId: data.pedidosId,
            estadoId: "",
        }
        if(data.estadoCodigo !== "01" && data.estadoCodigo !== "07"){
            createTickedVenta(data)
        }
        else {
            if(data.estadoCodigo === "07"){
                model.estadoId = "f1c231d0-cae2-4c36-a97e-9db1437e7776";
            }
            else if (data.estadoCodigo === "01" && data.tipoPagoId === "1eb0f4b2-dd43-4e18-92bc-d26675e54c5d"){
                model.estadoId = "8d024805-4471-40cf-ada1-d9d0b60aaa35";
            }
            else if(data.estadoCodigo === "01" && data.tipoPagoId === "c81c314c-40a5-467c-b8ee-b2b6513b5704"){
                model.estadoId = "c81c314c-40a5-467c-b8ee-b2b6513b5704";
            }
            
            CrearActividadEstadoPedido(model).then(() => {
                fetchData(pedidoId);
                createTickedVenta(data)
            }).catch((error) => {
                showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
            });
        }
        setIsLoading(false);
    }

    const handlePrintTicked = (item) => {
        try{
            setIsLoading(true);
            item.nombreCompania = detalle.nombreCompania;
            item.rucCompania = detalle.rucCompania;
            item.nombreCliente = detalle.nombreCliente;
            item.nroDniCliente = detalle.nroDniCliente;
            item.deuda = detalle.deuda;
            tickedPagoCuota(item)
            setIsLoading(false);
        }
        catch(e){
            setIsLoading(false);
            showErrorAlert("Error al generar el ticked");
        }
    }

    const fetchData = async (id) => {
        try {
            setIsLoading(true);
            const data = await getDetalleServicio(id);
            setDetalle(data);
            setActividadPago(data.actividadPago);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showErrorAlert("Erro de servidor, intentelo de nuevo")
        }
    };
    
    const onClickSubirDocumento = () => {
        subirDocumento(objDoc).then(() => {
            showSuccessAlert('Registro Exitoso');
            setMdDocumento(false);
            fetchData(pedidoId);
        }).catch((error) => {
            showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
        });
    }
    
    const onChangeFile = async(file) => {
        const response = await converBase64(file);
        setObjDoc({...objDoc, 
            documentoId: detalleDocumento.id,
            pedidoId: pedidoId,
            data: response
        });
    }

    useEffect(() => {
        fetchData(pedidoId);
    },[pedidoId])

  return (
    <div>
        <div className='row'>
            <div className='col-12'>
                <main className="card">
                    <div className="card-body">
                        <div className="row">
                            <section className='col-sm-12 col-md-8 col-lg-10'>
                                <div className='card'>
                                    <main className='card-body'>
                                        <article className='row pb-3'>
                                            <div className='col-sm-12 col-md-4 col-lg-4'>
                                                <img src={detalle.ulrLogo} className="img-fluid" alt=""></img>
                                            </div>
                                            <div className='col-sm-12 col-md-4 col-lg-4'>
                                                <p className='text-center font-weight-bold m-0' style={{fontSize:'0.7rem'}}>SERVICIOS DE SEPELLO, CREMACIONES</p>
                                                <p className='text-center font-weight-bold m-0' style={{fontSize:'0.7rem'}}>Y TRASLADO DE CUERPO A NIVEL NACIONAL</p>
                                                <p className='text-center font-weight-bold m-0' style={{fontSize:'0.7rem'}}>Cel.: {detalle.telefonos}</p>
                                            </div>
                                            <div className='col-sm-12 col-md-4 col-lg-4'>
                                                <div className='bg-primary border border-primary p-1'>
                                                    <p className='text-white text-center m-0'>CONTRATO</p>
                                                </div>
                                                <div className='border border-primary p-1'>
                                                    <p className='text-danger text-center font-weight-bold m-0'>{detalle.numComprobante}</p>
                                                </div>
                                            </div>
                                        </article>
                                        <article className='row mb-3'>
                                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                                <div className='w-25 bg-primary float-left border border-primary p-1'>
                                                    <p className='text-white font-weight-bold text-center m-0'>Fecha:</p>
                                                </div>
                                                <div className='w-75 border border-primary float-right p-1'>
                                                    <p className='m-0'>{detalle.fecha}</p>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-8'>
                                                <div className='border border-primary p-1'>
                                                    <div className='row'>
                                                        <div className='col-auto col-lg-6'>
                                                            <ItemName 
                                                                className='m-0'
                                                                titulo={"Seguro:"}
                                                                detalle={detalle.nombreSeguro}
                                                            />
                                                        </div>
                                                        <div className='col-auto col-lg-6'>
                                                            <ItemName 
                                                                className='m-0'
                                                                titulo={"Tipo Servicio:"}
                                                                detalle={detalle.nombreTipoServicio}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <article className='row'>
                                            {
                                            detalle.nombreTipoServicio === "VENTA" && (
                                            <>
                                                <div className='col-sm-12 col-md-6 col-lg-8'>
                                                    <ItemName 
                                                        titulo={"Finado(a):"}
                                                        detalle={detalle.nombreFinado}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                                    <ItemName 
                                                        titulo={"DNI:"}
                                                        detalle={detalle.nroDniFinado}
                                                    />
                                                </div>
                                            </>)
                                            }
                                            
                                            <div className='col-sm-12 col-md-6 col-lg-8'>
                                                <ItemName 
                                                    titulo={"Contratante:"}
                                                    detalle={detalle.nombreCliente}
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                                <ItemName 
                                                    titulo={"DNI:"}
                                                    detalle={detalle.nroDniCliente}
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                                <ItemName 
                                                    titulo={"Celular:"}
                                                    detalle={detalle.celularUno}
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                                <ItemName 
                                                    titulo={"Celular:"}
                                                    detalle={detalle.celularDos}
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                                <ItemName 
                                                    titulo={"Celular:"}
                                                    detalle={detalle.celularTres}
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                                <ItemName 
                                                    titulo={"Dirección:"}
                                                    detalle={detalle.direccion}
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                                <ItemName 
                                                    titulo={"Fecha Fallecimiento:"}
                                                    detalle={detalle.fechaFallecimiento}
                                                />
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                                <ItemName 
                                                    titulo={"Tipo Pago:"}
                                                    detalle={detalle.tipoPago}
                                                />
                                            </div>
                                        </article>
                                        <article className='table-responsive'>
                                            <table className='table table-sm table-borderless border border-primary'>
                                                <thead className='bg-primary'>
                                                    <tr>
                                                        <th className='text-white'>DESCRIPCIÓN DEL SERVICIO</th>
                                                        <th className='text-white'>FECH. INSTALACIÓN</th>
                                                        <th className='text-white'>FECH. RECOJO</th>
                                                        <th className='text-white'>Cant.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        detalle.detalles && detalle.detalles.map((item,index) => (
                                                            <tr key={index} className='border-bottom border-primary'>
                                                                <td>{item.nombre}</td>
                                                                <td>{item.fechaEntrega}</td>
                                                                <td>{item.fechaRecojo}</td>
                                                                <td>{item.cantidad}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </article>
                                        <article className='row mt-3'>
                                            <div className='col-sm-12 col-md-6 col-lg-6 d-none d-md-block d-lg-block'>
                                                {
                                                    detalle.documentos && detalle.documentos.map((item,index) => 
                                                    <p key={index}><i className='fa fa-download  text-primary pr-1' data-toggle="tooltip" data-placement="right" title="Descar documento" onClick={() => {setMdDocumento(true); setDetalleDocumento(item)}} style={{cursor:'pointer'}}/> {item.nombre} </p>)
                                                }
                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                                <div className='table-responsive'>
                                                    <table className='table table-sm table-borderless'>
                                                        <thead className='bg-primary border border-primary'>
                                                            <tr>
                                                                <th className='text-white text-center' colSpan={2}>COSTO TOTAL DEL SERVICIO</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className='border border-primary wrapper text-wrap w-75'>
                                                                    <p className='m-0 font-weight-bold' style={{fontSize:'0.7rem'}}>CRÉDITO POR SEGURO</p>
                                                                    <p style={{fontSize:'0.65rem'}} className='m-0'>Se compromenten en cancelar cuando desembolse el seguro</p>
                                                                </td>
                                                                <td className='border border-primary w-25'>
                                                                    <p className='font-weight-bold float-right'>{detalle.coberturaSegur && parseFloat(detalle.coberturaSegur).toFixed(2)}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border border-primary w-75'>
                                                                    <p className='m-0 font-weight-bold' >Cancelarán en Efectivo</p>
                                                                </td>
                                                                <td className='border border-primary w-25'>
                                                                    <p className='font-weight-bold float-right'>{parseFloat(detalle.precioNeto).toFixed(2)}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border border-primary w-75'>
                                                                    <p className='m-0 font-weight-bold float-right' >IGV 18% S/: </p>
                                                                </td>
                                                                <td className='border border-primary w-25'>
                                                                    <p className='font-weight-bold float-right'>{parseFloat(detalle.impuesto).toFixed(2)}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='w-75'>
                                                                    <p className='m-0 font-weight-bold float-right' >Total a Pagar S/: </p>
                                                                </td>
                                                                <td className='border border-primary w-25'>
                                                                    <p className='font-weight-bold float-right'>{parseFloat(detalle.precioBruto).toFixed(2)}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </article>
                                        <article className='row mt-5'>
                                            {
                                                detalle.deuda > 0 && 
                                                <UserPermissionsComponent accion={['admin','admVenta']}>
                                                    <div className='col-12 pb-2'>
                                                        <button className='btn btn-outline-primary btn-sm float-right' onClick={() => setMdRegistroPago(true)}><i className='fa fa-plus'></i> Actividad Pago</button>
                                                    </div>
                                                </UserPermissionsComponent>
                                            }
                                           
                                            
                                            <div className='col-12'>
                                            {
                                                (actividadPago.length > 0 || mdRegitroPago) && <PaymentActivity listaPagos={detalle.actividadPago} opc = {false} handlePrint={(item) => handlePrintTicked(item)}/>
                                            }
                                            {
                                                detalle.deuda > 0 &&  <h6 className="text-right text-danger mt-3">Deuda Pendiente S/. {parseFloat(detalle.deuda).toFixed(2)}</h6>
                                            }
                                                      
                                            </div>
                                        </article>
                                        
                                        <article className='row mt-3'>
                                            <div className='col-sm-12 col-md-6 col-lg-6'>

                                            </div>
                                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                                <div className='border border-dark p-1'>
                                                    <p className='text-center font-weight-bold m-0'>PROMOCIÓN / OBSERVACIÓN</p>
                                                </div>
                                                <div className='bg-light p-1'>
                                                    <p>{detalle.descripcion}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </main>
                                </div>
                            </section> 
                            <aside className='col-sm-12 col-md-4 col-lg-2'>
                                <article className='row mt-2'>
                                    <div className='col-sm-6 col-md-4 col-lg-12'>
                                        <button className='btn btn-outline-success btn-icon-text float-right mb-3 w-100' onClick={() => onClickDescargarPdf(pedidoId)}>
                                            <i className='ti-upload btn-icon-prepend'/>
                                            Descargar
                                        </button>
                                    </div>
                                    <div className='col-sm-6 col-md-4 col-lg-12'>
                                        <button className='btn btn-outline-primary btn-icon-text float-right mb-3 w-100' onClick={() => compartirPorWhatsApp(pedidoId,detalle.celularUno)}>
                                            <i className='mdi mdi-whatsapp btn-icon-prepend'/>
                                            Compartir
                                        </button>
                                    </div>
                                    <UserPermissionsComponent accion={['admin','admVenta']}>
                                        <div className='col-sm-6 col-md-4 col-lg-12'>
                                            <button className='btn btn-outline-warning btn-icon-text float-right mb-3 w-100' onClick={() => onClickImprimir(detalle)}>
                                                <i className='ti-printer btn-icon-prepend'/>
                                                {
                                                    (detalle.estadoCodigo === "07" || detalle.estadoCodigo === "01") ? "Confirmar": "Imprimir"
                                                }
                                                
                                            </button>
                                        </div>
                                    </UserPermissionsComponent>
                                </article>
                                {/* ACTIVIDAD PAGO */}
                                <ModalComponents
                                    size={"md"}
                                    show={mdRegitroPago}
                                    hide={() => setMdRegistroPago(false)}
                                    titulo={"Registro de Pagos"}
                                >
                                    <PaymentRates 
                                        onChangeData={(value) => onSaveActivityPayment(value)} 
                                        totalPagoDeuda={actividadPago.reduce((acumulador, actual) => acumulador + parseFloat(actual.monto), 0)}
                                        precioTotal={detalle.precioBruto} 
                                        nroRecibo = {`T${actividadPago.length + 1}-${detalle.numComprobante}`}
                                    />
                                </ModalComponents>

                                {/* ACTIVIDAD PAGO */}
                                <ModalComponents
                                    size={"sm"}
                                    show={mdDocumento}
                                    hide={() => setMdDocumento(false)}
                                    titulo={"Bodega de Documento"}
                                >
                                    <main className='row'>
                                        <section className='col-12 '>
                                            <p className='text-center'>{detalleDocumento.nombre}</p>
                                        </section>
                                        <section className='col-12 text-center'>
                                            <div className='text-center p-1'>
                                                {
                                                    detalleDocumento.isCargado ?
                                                        <i className='fa fa-file-pdf-o text-primary pr-1 icon-lg' data-toggle="tooltip" data-placement="right" title="Descargar" style={{cursor:'pointer'}} onClick={() => window.open(detalleDocumento.urlDocumento,"_blank")}/>
                                                        : 
                                                        <i className='fa fa-file-pdf-o text-secondary pr-1 icon-lg' data-toggle="tooltip" data-placement="right" title="Documento no encontrado" />
                                                }
                                            </div> 
                                        </section>
                                        <section className='col-12'>
                                            <div className='row mt-4'>
                                                <div className='col-lg-9 col-md-9 col-sm-12'>
                                                    <Form.File 
                                                        id="custom-file"
                                                        label="Cargar archivo"
                                                        onChange = {(e) => onChangeFile(e.target.files[0])}
                                                        data-browse="Subir"
                                                        lang="en"
                                                        custom
                                                    />
                                                </div>
                                                <div className='col-lg-3 col-md-3 col-sm-12'>
                                                    <Button label = {detalleDocumento.isCargado ? 'Modificar' : 'Subir'} className= 'btn btn-primary float-right btn-sm' onClick={() => onClickSubirDocumento()} />
                                                </div>
                                                
                                                
                                            </div>
                                        </section>
                                    </main>
                                </ModalComponents>
                            
                            </aside>
                        </div>
                    </div>
                
                </main>
            </div>
        </div>
        <Loading isLoading = {isLoading}/>
    </div>
  )
}

export default DetailSales