import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Select from "react-select";
import { getCardInfo, getGraficaLineal, getGraficaPie } from '../../services/ChecklistService';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { decodeToken } from "react-jwt";
import accessibility from 'highcharts/modules/accessibility';
accessibility(Highcharts);

const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Obtiene el año actual
    const years = [];
    for (let year = 2000; year <= currentYear; year++) {
        years.push({ value: year, label: year.toString() });
    }
    return years.reverse();
};

const customThemeFn = (theme) => ({ 
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 3,
      baseUnit: 3,
    }
    
})

const  Dashboard = () => {
    const [ cardInf, setCardInfo ] = useState({});
    const [ graficaLine, setGraficaLine ] = useState({});
    const [ graficaPie, setGraficaPie] = useState([]);
    const [ nombrePerfil, setNombrePerfil] = useState("");

    const years = generateYearOptions(); 
    const currentYear = new Date().getFullYear(); 

    const onSelectAnio = (item) =>{
        getData(item.value);
    }

    const getData = async (anio) => {
        const data = await getCardInfo(anio);
        const line = await getGraficaLineal(anio);
        const pie = await getGraficaPie(anio);
        setCardInfo(data);
        setGraficaLine(line);
        setGraficaPie(pie);
    }   

    const options = {
        title: false,
        xAxis: {
            categories: graficaLine.meses,
        },
        yAxis: {
            title: {
                text: 'Cantidad de Ventas y Alquiler'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y}'
                },
            }
        },
        series: [{
            type: 'column',
            colorByPoint: false,
            data: graficaLine.cantidad,
            showInLegend: false,
            
        }]
    }

    const pieOpcion = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
            type: 'pie'
        },
        title: false,
        credits: {
          enabled: false
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b><br>{point.percentage:.1f}%',
                    distance: 10,
                    filter: {
                        property: 'percentage',
                        operator: '>',
                        value: 4
                    }
                }
            }
        },
        //colors: estado.map(x => x.color),
        series: [{
          name: "Vendidos",
          data: 
            graficaPie.map((item) => {
              return {
                name: item.nombre,
                y: item.cantidad
              }  
               
            })     
          
          }]
      }

    useEffect(() => {
        var decode = decodeToken(sessionStorage.getItem("jwt"));
        if(decode.hasOwnProperty('Nombre')){
            setNombrePerfil(decode.Nombre);
        }
    },[])

    useEffect(() => {
        getData("");
    },[])
    return (
        <div>
            <section className="row">
                <div className="col-12 col-xl-5 mb-4 mb-xl-0 grid-margin">
                    <h4 className="font-weight-bold">Hola, {nombrePerfil}</h4>
                    <h4 className="font-weight-normal mb-0">Bienvenido a Villa Fe</h4>
                </div>
                <div className="col-12 col-xl-7 grid-margin">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                    {
                        cardInf.tipoProducto && cardInf.tipoProducto.map((item,index) => (
                            <div className="border-right pr-4 mb-3 mb-xl-0" key={index}>
                                <p className="text-muted">{item.nombre}</p>
                                <h4 className="mb-0 font-weight-bold">{item.cantidad}</h4>
                            </div>
                        ))
                    }
                    <div className="mb-3 mb-xl-0">
                        <Link className="btn btn-warning rounded-0 text-white" to={"/activities"}>Lista Actividades</Link>
                    </div>
                </div>
                </div>
            </section>
            <section className='row mb-2'>
                <div className='col-12'>
                    <div className="float-right" style={{width:'15%'}}>
                        <Select
                            theme={customThemeFn}
                            options={years}
                            defaultValue={{ value: currentYear, label: currentYear.toString() }} 
                            onChange={(item) => onSelectAnio(item)}
                        />
                    </div>
                </div>
            </section>
            <section className="row">
                {
                    cardInf.cardInf && cardInf.cardInf.map((item,index) => (
                        <div key={index} className="col-md-3 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <p className="card-title text-md-center text-xl-left">{item.nombre}</p>
                                    <div className="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                                    <h3 className="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{item.cantidad}</h3>
                                    <i className = {`${item.icono} icon-md text-muted mb-0 mb-md-3 mb-xl-0`}></i>
                                    </div>  
                                    {/* <p className="mb-0 mt-2 text-warning">2.00% <span className="text-black ml-1"><small>(30 days)</small></span></p> */}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </section>
            <section className='row'>
                <div className="col-sm-12 col-md-8 col-lg-7 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <p className='card-title'>Gráfica de Ventas y Alquiler</p>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-5 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <p className='card-title'>Venta de Productos</p>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={pieOpcion}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Dashboard;